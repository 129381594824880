.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
    max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
    display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;

    img[class*="wp-image-"] {
        @include center-block;
    }

    .wp-caption-text {
        margin: 0.8075em 0;
    }
}

.wp-caption-text {
    text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
    margin-bottom: 1.5em;
}

.gallery-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
    width: 100%;

    // Loops to enumerate the classes for gallery columns.
    @for $i from 2 through 9 {
        .gallery-columns-#{$i} & {
            max-width: map-get($columns, $i);
        }
    }
}

.gallery-caption {
    display: block;
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 1.5em;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 1.5em;
}

.aligncenter {
    clear: both;
    @include center-block;
}


.alignwide {
    width: 100vw;
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    clear: both;
}

.alignfull {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: auto;
    max-width: 1000%;
    float: left;
}

.wp-block-image.alignfull img {
    width: 100vw;
}

.wp-block-video video {
    max-width: 636px;
}

.wp-block-image img {
    display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
    width: 100%
}

.wp-block-gallery:not(.components-placeholder) {
    margin: 1.5em auto;
}

.wp-block-cover-text p {
    padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
    padding: 0 14px;
}

.wp-block-table {
    display: block;
    overflow-x: auto;
}

.wp-block-table table {
    border-collapse: collapse;
    width: 100%
}

.wp-block-table td, .wp-block-table th {
    padding: .5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.wp-block-quote.is-large {
    margin: 0 auto 16px;
}

.wp-block-pullquote>p:first-child {
    margin-top: 0;
}

.wp-block-separator {
    margin: 3em auto;
    padding: 0;
}

/* Text meant only for screen readers. */
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

    &:focus {
        background-color: $color__background-screen;
        border-radius: 3px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
        clip: auto !important;
        clip-path: none;
        color: $color__text-screen;
        display: block;
        @include font-size(0.875);
        font-weight: bold;
        height: auto;
        left: 5px;
        line-height: normal;
        padding: 15px 23px 14px;
        text-decoration: none;
        top: 5px;
        width: auto;
        z-index: 100000; /* Above WP toolbar. */
    }
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
    outline: 0;
}

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    @include clearfix;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
    @include clearfix-after;
}

/*--------------------------------------------------------------
## Queries
--------------------------------------------------------------*/
.mobile-menu {
    display: none;
    float: right;
    font-size: 15px;
    color: #000;
    position: relative;
    top: 30px;
    z-index: 99;
    cursor: pointer;
    &.nav-is-visible {
        display: block;
        top: -10px;
        right: 10px;
        span {
            background: transparent;
            &:before {
                -webkit-transform: translateX(4px) translateY(-1px) rotate(45deg);
                -moz-transform: translateX(4px) translateY(-1px) rotate(45deg);
                -ms-transform: translateX(4px) translateY(-1px) rotate(45deg);
                -o-transform: translateX(4px) translateY(-1px) rotate(45deg);
                transform: translateX(4px) translateY(-1px) rotate(45deg);
            }
            &:after {
                -webkit-transform: translateX(4px) translateY(0px) rotate(-45deg);
                -moz-transform: translateX(4px) translateY(0px) rotate(-45deg);
                -ms-transform: translateX(4px) translateY(0px) rotate(-45deg);
                -o-transform: translateX(4px) translateY(0px) rotate(-45deg);
                transform: translateX(4px) translateY(0px) rotate(-45deg);
            }
        }
    }
    span {
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -2px;
        -webkit-transition: rotate 0.2s, translate 0.2s;
        -moz-transition: rotate 0.2s, translate 0.2s;
        transition: rotate 0.2s, translate 0.2s;
        display: inline-block;
        height: 3px;
        width: 24px;
        background: #000;
        &::before, &::after {
            position: absolute;
            display: inline-block;
            height: 3px;
            width: 24px;
            background: #000;
            content: '';
            right: 0;
            -webkit-transform: translateZ(0);
            -moz-transform: translateZ(0);
            -ms-transform: translateZ(0);
            -o-transform: translateZ(0);
            transform: translateZ(0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -webkit-transform-origin: 0% 50%;
            -moz-transform-origin: 0% 50%;
            -ms-transform-origin: 0% 50%;
            -o-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transition: -webkit-transform 0.2s;
            -moz-transition: -moz-transform 0.2s;
            transition: transform 0.2s;
        }
    }
}

.mobile-menu span:before {
    top: -8px;
}

.mobile-menu span:after {
    top: 8px;
}

@media (max-width: 1023px) {
    #mobile-open {
        display: block;
        height: 25px;
        width: 24px;
        position: relative;
        top: 14px;
        right: 0px;
    }
    .nav-form.open {
        background: rgba(0, 0, 0, 0.8);
        visibility: visible;
    }
    #site-navigation {
        display: none;
    }

    .header-default {
        padding: 10px 0;
        #mobile-open {
            top: -40px;
        }
    }
    .header-bottom {
        .header-socials {
            width: auto;
        }
    }
    
    .header-type3, .header-type2 {
        #mobile-open {
            float: left;
            top: 14px;
            span {
                left: 0;
                background: rgb(255, 255, 255);
                &::before, &::after {
                    background: rgb(255, 255, 255);
                }
            }
            &.nav-is-visible {
                left: 0;
                span {
                    background: transparent;
                }
            }
        }
    }
    
    .header-type4 {
        .logo-mobile {
            display: block;
        }
    }
    .header-4 {
        .nav-menu ul li.site-logo {
            display: none;
        }
    }
}

@media (max-width: 768px) {
    .course-results {
        display: none;
    }
    .switch-layout-container .learn-press-search-course-form {
        width: 80%;
    }
}

@media (max-width: 590px) {
    .header-top .header-contact-wrapper {
        float: none;
        li {
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
            margin-left: 0;
            text-align: center;
            .box-text {
                float: left;
            }
        }
    }

    #lms-course-list [class^='col-lg'] .course-thumbnail {
        width: 100%; 
    }

    #lms-course-list [class^='col-lg'] .course-content {
        width: 100%;
        padding: 0;
    }
    #lms-course-list [class^='col-lg'] .course-content .course-meta {
        margin: 0;
    }

}

@media (max-width: 499px) {

    .titlebar .header-title {
        font-size: 30px;
    }
    .single-lp_course .course-meta > div {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .feature-slider .slick-slide .feature-slide-inner .feature-slide-content h2.feature-slide-heading {
        font-size: 30px !important;
    }

    #lms-course-list li.col-md-4 .course-thumbnail {
        width: 100%;
        margin-bottom: 15px;
    }
    #lms-course-list li.col-md-4 .course-content {
        width: 100%;
        padding-left: 0;
    }

    .switch-layout-container .learn-press-search-course-form {
        width: calc(100% - 70px);
    }
    .header-type3 .header-bottom a.nav-btn-right {
        margin-right: -15px;
    }

    .course-grid-layout2 .intro-item .all-course {
        position: relative !important;
        padding: 30px;
    }
    .carousel-wrapper .btn-view-all {
        text-align: center !important;
    }
    .carousel-wrapper .btn-view-all a.btn-all-courses {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .carouse-grid .course-grid-box {
        margin-top: 5px !important;
    }
}