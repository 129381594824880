@media (min-width: 1200px) {
    .container {
        max-width: 1230px;
    }
}

#page {
    transition: margin-left .5s;
    overflow: hidden;
}

.site-title {
    margin: 10px 0 5px;
    color: $color__text-main;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 1;
    a {
        color: $color__text-main;
        text-decoration: none;
        &:hover {
            color: $color__text-main;
        }
    }
}

.site-branding {
    position: relative;
    z-index: 99;
    div {
        float: left;
    }
    .site-logo {
        max-width: 90px;
        margin-right: 10px;
    }
}

.site-description {
    margin: 0;
    font-size: 12px;
    color: $color__text-main;
}

.site-content {
    position: relative;
    overflow: hidden;
}

#main, #secondary {
    padding-top: 50px;
    padding-bottom: 50px;
}

#main {
    border-right: 1px solid #e1e2e4;
    padding-right: 25px;
}

.sidebar-inner {
    padding-left: 10px;
}

.full-width #main,
.no-sidebar #main {
    border: none;
    padding-right: 15px;
}

.single.left-sidebar {
    #main {
        border-left: 1px solid #e1e2e4;
        padding-left: 25px;
        border-right: none;
        padding-right: 0;
    }
    .sidebar-inner {
        padding-right: 10px;
        padding-left: 0;
    }
}

/*--------------------------------------------------------------
## Topbar
--------------------------------------------------------------*/
.topbar {
    font-style: normal;
    color: #aaaaaa;
    font-size: 12px;
    background: #111;
    position: relative;
    z-index: 99;
    .widget_text {
        float: left;
    }
    .topbar-right {
        text-align: right;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 11px 0 12px;
        li {
            display: inline-block;
            margin-right: 15px;
            margin-bottom: 0;
            line-height: 14px;
            a {
                color: rgb(255, 255, 255);
                font-size: 12px;
            }
            i {
                font-size: 14px;
                margin-right: 5px;
            }
        }
    }
    .header_login_url {
        margin: 11px 0 12px 40px;
        line-height: 1;
        a {
            display: inline-block;
            vertical-align: bottom;
            text-decoration: none;
            line-height: 1;
            color: #aaaaaa;
            &:hover {
                color: #fff;
            }
            i {
                font-size: 14px;
                margin-right: 10px;
            }
        }
        .vertical_divider {
            display: inline-block;
            vertical-align: bottom;
            margin: 0 8px 0;
            width: 1px;
            height: 12px;
            background-color: #9a9a9a;
        }
    }
}

.header-default {
    padding: 20px 0 0;
    background: #fff;
    box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.06);
    .main-navigation ul {
        float: right;
    }
}

.cart-contents {
    font-size: 12px;
    color: #fff;
    margin: 0 2px;
    display: block;
    cursor: pointer;
    z-index: 1;
    position: relative;
    border-left: 1px solid rgba(255, 255, 255, 1);
    padding-left: 10px;
    width: auto !important;
    .sp-count {
        background: #fff;
        color: #000;
        display: inline-block;
        width: 15px;
        height: 15px;
        text-align: center;
        line-height: 15px;
        border-radius: 100%;
        font-size: 12px;
        position: absolute;
        top: -10px;
        left: 13px;
    }
}

/*--------------------------------------------------------------
## Titlebar
--------------------------------------------------------------*/
.titlebar {
    padding: 5% 0;
    background-color: #457992;
    text-align: center;
    position: relative;
    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
    }
    .container {
        position: relative;
        z-index: 10;
    }
    .header-title {
        font-size: 50px;
        color: #fff;
        font-family: 'Montserrat';
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .archive-description {
        p {
            color: #fff;
            font-size: 18px;
            margin-bottom: 1em;
        }
    }

    .triangled_colored_separator {
        width: 50px;
        display: inline-block;
        margin: 0 auto;
        height: 2px;
        background: $color__link-hover;
    }
}

.breadcrumbs {
    background-color: #f6f6f6;
    padding: 13px 0;
    line-height: 1;
    margin: 0;
    border-top: 1px solid #f0f2f5;
    border-bottom: 1px solid #f0f2f5;
    color: #999;
    font-size: 12px;
    span {
        color: #999;
        margin-right: 7px;
        margin-left: 7px;
        span {
            margin-right: 0;
            margin-left: 0;
        }
    }
    span:first-child {
        margin-left: 0;
    }
    a:hover {
        text-decoration: none;
        span {
            color: $color__link-hover;
        }
    }
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
    display: block;
}

.hentry {
    margin: 0 0 3.5em;
}

.updated:not(.published) {
    display: none;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 1.5em 0 0;
    clear: both;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

.blog-content {
    .page-header {
        margin-top: 0;
    }
}

.blog, .archive, .search {
    .entry-header {
        overflow: hidden;
        .entry-date {
            font-size: 60px;
            font-weight: 700;
            height: 85px;
            width: 95px;
            float: left;
            line-height: 60px;
            color: $color__link-hover;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 1px;
                top: 4px;
                right: 0;
                bottom: 5px;
                background: #eee;
            }
            i {
                font-size: 13px;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 700;
                display: block;
                line-height: 20px;
                margin-top: 3px;
            }
        }

        .entry-contain {
            position: relative;
            padding-left: 30px;
            width: calc(100% - 95px);
            width: -webkit-calc(100% - 95px);
            width: -moz-calc(100% - 95px);
            float: right;

        }
    }

}

.entry-meta {
    a {
        color: $color__text-main;
    }
    span {
        margin-right: 20px;
        font-size: 14px;
        display: inline-block;
        i {
            color: $color__link-hover;
            margin-right: 5px;
        }
        span {
            margin-right: 0;
        }
    }
}

.single, .archive {
    .entry-meta {
        margin-bottom: 1.5em;
    }

}

body #content .entry-footer {
    display: block;
    .social-share li {
        a {
            color: #999;
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            border: 1px solid #999;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                color: #fff;
            }
        }

        div.facebook-social a:hover {
            border-color: #3b5998;
            background-color: #3b5998;
        }

        div.googleplus-social a:hover {
            border-color: #dd4b39;
            background-color: #dd4b39;
        }
        div.twitter-social a:hover {
            border-color: #00aced;
            background-color: #00aced;
        }
        div.pinterest-social a:hover {
            border-color: #cb2027;
            background-color: #cb2027;
        }
    }
}

.entry-title {
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    margin: 0 0 7px;
    a {
        color: $color__text-main;
        &:hover {
            color: $color__link-hover;
            text-decoration: none;
        }
    }
}

h1.entry-title {
    margin-bottom: 15px;
}

.readmore a {
    background: none;
    color: $color__text-main;
    border: 2px solid $color__text-main;
    border-radius: 2px;
    padding: 10px 15px;
    display: inline-block;
    line-height: 1;
    &:hover {
        text-decoration: none;
        color: $color__text-main;
        border-color: $color__link-hover;
        background: transparent;
    }
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
    word-wrap: break-word;
}

.bypostauthor {
    display: block;
}

.comment-list {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
        margin: 30px 0;
        padding-bottom: 30px;
        border-bottom: 1px solid #e6e6e6;
        .avatar {
            border-radius: 3px;
            float: left;
            width: 60px;
        }
        .comment-wrapper {
            background: #f8f9f9 none repeat scroll 0 0;
            margin-left: 6em;
            padding: 20px;
            position: relative;
            &::before {
                border-color: transparent #f6f7f9 transparent transparent;
                border-style: solid;
                border-width: 0 10px 10px 0;
                content: "";
                height: 0;
                left: -9px;
                position: absolute;
                top: 0;
                width: 0;
            }
            .comment-meta {
                margin-bottom: 20px;
                a {
                    text-decoration: none;
                }
            }
            .comment-actions i {
                padding-left: 10px;
                font-size: 13px;
            }
        }
        ul, ol {
            margin-bottom: 0;
            margin-left: 1.5em;
            list-style: none;
        }
        li {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.comment-respond {
    input.submit {
        width: auto;
    }
}

.search .page-header .page-title {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.site-footer {
    background: #111;
    color: #999;
    padding-top: 25px;
    font-size: 13px;
    .footer-widgets {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .widget-title {
        position: relative;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 40px;
        color: #fff;
        margin: 0 0 15px;
        letter-spacing: 1px;
    }
    .copyright-area {
        padding-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid #222;
        line-height: 30px;
        span {
            color: $color__link-hover;
        }
        ul {
            margin-bottom: 0;
            li {
                display: inline-block;
                padding-right: 5px;
                padding-left: 5px;
            }
        }
        a:hover {
            color: #999;
            text-decoration: underline;
        }
    }
    a {
        color: #999;
        line-height: 30px;
        &:hover {
            text-decoration: none;
            color: $color__link-hover;
        }
    }

    .footer-social {
        float: left;
        padding: 10px 18px;
        color: #FFF;
        background: $color__link-hover;
        margin-top: -41px;
        position: relative;
        &::after {
            border-right: 9px solid #777;
            border-top: 16px solid transparent;
            content: "";
            display: block;
            height: 0;
            left: -9px;
            position: absolute;
            top: 0;
        }
        label {
            font-size: 13px;
            margin-right: 10px;
            color: #fff;
            font-weight: normal;
        }
        a {
            color: #fff;
            margin-right: 3px;
            &:hover {
                i {
                    color: $color__text-main;
                    border-color: $color__text-main;
                }
            }
            i {
                padding: 0;
                border-radius: 50%;
                font-size: 13px;
                line-height: 25px;
                height: 25px;
                width: 25px;
                text-align: center;
                border: 1px solid #fff;
                color: #fff;
            }
        }
    }
}

.contact-info-item {
    margin-bottom: 10px;
    .contact-text {
        display: block;
        float: left;
        width: 30px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-top: 2px;
        position: relative;
        i {
            font-size: 16px;
        }
    }
    .contact-value {
        display: block;
        margin-left: 35px;
    }

}

.contact-info-box {
    ul {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        li {
            padding: 8px 0;
            border-top: 1px dotted rgba(255, 255, 255, 0.1);
            &:first-child {
                border-top: 0;
            }
            span {
                float: right;
                font-weight: bold;
            }
        }
    }
}

/*--------------------------------------------------------------
## Course
--------------------------------------------------------------*/

.post-type-archive-lp_course {
    #content .no-sidebar {
        padding-right: 15px;
        border: none;
    }
}

ul.learn-press-courses.row {
    margin-right: -15px;
    margin-left: -15px;
    li {
        margin-bottom: 30px;
    }
}

#lms-course-grid {
    .course-description, .course-readmore {
        display: none;
    }
}

#lms-course-list {
    [class^='col-lg'] {
        width: 100%;
        max-width: 100%;
        flex: 100%;
        .course-item {
            overflow: hidden;
            padding-bottom: 30px;
            border-bottom: 1px solid #e3e3e3;
        }
        .course-thumbnail {
            width: 30%;
            float: left;
           
        }
        .course-content {
            width: 70%;
            float: left;
            text-align: left;
            border: none;
            padding-left: 30px;
           
            .course-author {
                display: none;
            }
            .course-meta {
                margin-left: 0;
                padding-left: 0;
                padding-right: 0;
                border: none;
                .pull-left {
                    display: none;
                }
                .course-price {
                    float: left !important;
                    margin-top: 7px;
                }
                .course-readmore {
                    float: right;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 12px;
                    border: 1px solid #000;
                    padding: 10px 15px 9px;
                    line-height: 1;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            h2.course-title {
                font-size: 22px;
                font-weight: bold;
                a:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.single-lp_course {
    .course-thumbnail {
        margin-bottom: 30px;
    }
    .course-meta {
        display: block;
        overflow: hidden;
        position: relative;
        padding: 0;
        margin-bottom: 30px;
        label {
            font-size: 12px;
            font-weight: 700;
            color: #ccc;
            margin-bottom: 0;
        }
        > div {
            line-height: 20px;
            margin-right: 15px;
            margin-bottom: 0;
            padding-right: 15px;
            color: #ccc;
            border-right: 1px solid #eee;
            float: left;
            &:last-child {
                border-right: 0;
            }
            .value {
                font-size: 12px;
                text-transform: uppercase;
                font-weight: 700;
                color: #7a7a7a;
                line-height: 16px;
            }
            a {
                color: #7a7a7a;
            }
        }
        .course-author {
            img {
                border-radius: 50%;
                width: 40px;
                height: auto;
                float: left;
                margin-right: 10px;
            }
            .author-contain {
                display: inline-block;
            }
        }
        .course-review .review-stars-rated {
            margin-top: 1px;
            .filled {
                font-size: 0;
            }
        }
    }

    .course-price {
        .origin-price {
            font-size: 18px;
            text-decoration: line-through;
            font-style: italic;
        }
        .price {
            color: #f24c0a;
            font-size: 30px;
            font-weight: 700;
            margin-right: 0;
            display: inline-block;
        }
    }

    .course-info {
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 30px;
        li {
            padding: 5px 10px;
            text-align: center;
            i {
                color: $color__link-hover;
                margin-right: 5px;
            }
        }
    }

    .lp-single-course {

        .course-meta {
            display: none;
        }

        ul.learn-press-nav-tabs {
            border: none;
            background: #eaeaea;
            margin-bottom: 30px;
            a {
                text-decoration: none;
                padding: 10px 30px;
            }
            li {
                text-align: center;
                &:after {
                    height: 0;
                }
            }
            .course-nav.active, .course-nav:hover {
                background: $color__link-hover;
                a {
                    color: #fff;
                }
            }
        }

        .course-curriculum {
            ul.curriculum-sections .section-header {
                border-bottom-color: $color__link-hover;
            }
            .section-content {
                .course-item .section-item-link:hover {
                    text-decoration: none;
                }
            }

        }
        .course-rate .review-bar .rating {
            background: $color__link-hover;
        }

        .course-reviews-list, .course-reviews-list-shortcode {
            padding-left: 0;
            li {
                border: none;
                background: #f8f9f9;
                .review-author-info {
                    float: left;
                    img {
                        border-radius: 3px;
                        max-width: 70px;
                    }
                    .user-name {
                        margin: 0 0 5px 0;
                    }
                    .review-title {
                        margin-top: 0;
                    }
                }
                .review-text {
                    float: left;
                    width: 100%;
                    clear: both;
                }
            }
        }

        #learn-press-course-tabs .course-tab-panel {
            .course-author {
                margin-bottom: 30px;
                padding-bottom: 30px;
                border-bottom: 1px solid #EAEAEA;
                &:last-child {
                    border-bottom: none;
                }
                .author-name {
                    width: 100%;
                    text-align: left;
                    img {
                        float: left;
                        width: 100px;
                        height: 100px;
                    }
                    .socials {
                        margin-left: 120px;
                        a {
                            font-weight: bold;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                }
                .author-bio {
                    margin-top: 15px;
                }
            }
        }
    }
}

.lp-user-profile {
    .lp-sub-menu {
        padding-left: 0;
    }
    ul.learn-press-courses.profile-courses-list {
        margin-right: -15px;
        margin-left: -15px;
    }
}

.learnpress {
    .learn-press-pagination .page-numbers > li a, 
    .learn-press-pagination .page-numbers > li span {
        padding: 10px 15px;
        text-decoration: none;
    }
    
    .learn-press-pagination .page-numbers > li span {
        background-color: $color__link;
        border-color: $color__link;
        color: #fff;
    }
    
}

.course-results {
    display: inline-block;
    color: #999;
    font-size: 13px;
    font-style: italic;
    line-height: 34px;
    margin-left: 10px;
}
/*<if_is_premium>*/
.site-header {
    position: relative;
    z-index: 99;
    width: 100%;
    opacity: 1;
    transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    transform: translateY(0);
}

.header-transparent {
    /* in case enable sticky header */
    .site-header-wrap.no-fixed {
        width: 100%;
        background: transparent;
        .topbar, .header-default, .header-bottom, .header-top, .header-type4 {
            background: transparent;
            box-shadow: none;
        }
        .site-title a,
        .site-description {
            color: #fff;
        }
        .main-navigation ul {
            li {
                a {
                    color: #fff;
                    &:hover {
                        color: $color__link;
                    }
                }
                ul a {
                    color: $color__text-main;
                }
            }
        }
    }
    .site-header-wrap {
        position: absolute;
    }

    &.no-sticky {
        /* in case no enable sticky header */
        .site-header {
            background: transparent;
            position: absolute;
            .topbar, .header-top, .header-bottom, .header-type4 {
                background: transparent;
            }
            .header-default {
                box-shadow: none;
                background: transparent;
            }
            .site-title a,
            .site-description,
            .main-navigation a,
            .header-contact-wrapper li .box-text .contact-phone,
            .header-contact-wrapper li .box-text .contact-text {
                color: #fff;
            }
        }
    }
}

.site-header-wrap.fixed {
    width: 100%;
    .site-header {
        position: fixed;
        left: 0;
        top: 30px;
    }
}

#to-top {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 9999;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    background: #ffb606;
    color: #fff;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    font-size: 20px;
}

#to-top:hover {
    background: #222;
}

#to-top.show {
    opacity: 1;
}

.switch-layout-container {
    background: #f5f5f5;
    padding: 10px 10px 10px 20px;
    margin-bottom: 60px;
    min-height: 54px;
    .switch-layout {
        float: left;
        a {
            font-size: 18px;
            display: inline-block;
            line-height: 34px;
            margin-right: 5px;
            color: #ccc;
            &.switch-active, &:hover {
                color: #ffb606;
            }
        }
    }

    .learn-press-search-course-form {
        float: right;
        position: relative;
        width: 50%;
        margin: 0;
        display: flex;
        input {
            margin: 0;
            padding: 7px !important;
            &:focus {
                border-color: #ffb606;
            }
        }
        button {
            background: none;
            position: absolute;
            top: 0;
            right: 0;
            color: #ffb606;
            padding: 7px 13px;
            font-size: 14px;
        }
    }
}

/* Course search */
.courses-search-widget {
    position: relative;
    padding: 40px;
    color: #fff;
    border-radius: 5px;
    text-align: center;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color__link;
        opacity: 0.5;
        content: '';
        display: block;
    }
    .search-widget-inner {
        z-index: 10;
        position: relative;
    }
    h3 {
        color: #fff;
        margin-bottom: 7px;
        font-size: 36px;
        letter-spacing: 1.5px;
        font-weight: 700;
    }

    p {
        color: #fff;
        font-size: 18px;
    }
    .search-course-input {
        font-size: 16px;
        color: #999;
        padding: 10px;
        border-color: #fff;
        &:focus {
            border-color: #fff;
        }
    }
    .search-submit {
        position: absolute;
        top: 0px;
        height: 50px;
        right: 0;
        font-size: 17px;
        line-height: 1;

        &:focus {
            outline: none;
        }
    }
    .long_desc {
        text-align: center;
        margin: 0 -40px;

        ul {
            text-align: center;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                line-height: 30px;
                margin: 0 25px;

                i {
                    font-size: 20px;
                    color: #fff;
                    vertical-align: middle;
                    line-height: 30px;
                    margin-right: 8px;
                }

                a {
                    color: #fff;
                }
            }
        }
    }
}


/*</if_is_premium>*/